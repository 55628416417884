export const COLUMN_TO_TITLE = {
    'email': 'Електронна пошта',
    'phone_number': 'Номер телефону',
    'role': 'Роль',
    'is_active': 'Активний',
    'address': 'Адреса',
    'capacity': 'Місткість',
    'hourly_rate': 'Погодинна ставка',
    'license_plate': 'Номерний знак',
    'make': 'Марка',
    'white_listed': 'У білому списку',
    'amount': 'Сума',
    'automobile': 'Автомобіль',
    'parking_lot': 'Парковка',
    'payment_date': 'Дата оплати',
    'status': 'Статус',
    'name': 'Назва',
    'totalEarn': 'Загальний заробіток',
    'usage': 'Використання',
    'avgSession': 'Середня сесія',
    'total_parking_sessions': 'Кількість сесій',
    'total_earnings': 'Загальний дохід',
    'total_active_parking_sessions': 'Кількість активних сесій',
    'night_rate': 'Нічна ставка',
    'month_subscription': 'Підписка',
    'start_time': 'Час заїздy',
    'end_time': 'Час виїзду',
}

export const PARKING_ACTION_TO_TEXT = {
    'ENTERED': `Заїхав`,
    'LEFT': 'Виїхав',
    'ENDED': 'Завершив',
    'PAID': 'Оплатив',
    'AUTO_OPENED': 'Автоматично відкрито',
    'AUTO_STARTED': 'Автоматично розпочато сесію',
    'AUTO_CONTINUED': "Автоматично продовжено сесію",
    'AUTO_ENDED': "Автоматично завершено сесію",
}

export const SYSTEM_ACTION_TO_TEXT = {
    'DELETE': 'Видалив',
    'CREATE': 'Створив',
    'UPDATE': 'Оновив',
}

export const MODEL_TO_TEXT = {
    'User': 'Користувач',
    'ParkingLot': 'Парковка',
    'Automobile': 'Автомобіль'
}

export const ITEMS_PER_PAGE = 10

export const FILTER_TO_KEY = {
    'Сьогодні': '2',
    'Тиждень': '7',
    'Місяць': '30',
    'Рік': '365'
}

export const DISABILITY_STATUS_TO_TEXT = {
    "APPROVED": "Підтверджено",
    "REVIEW": "На розгляді",
    "REJECTED": "Відхилено",
    "DISABLED": "Відключено",
}