import { useEffect, useState } from "react";

import { convertDateToLocalFormat } from "../utils/functions";
import { useGetParkingEventLogsQuery } from "../api/appApi";
import { PARKING_ACTION_TO_TEXT } from "../utils/constants";
import usePagination from "../hooks/usePagination";
import { useAppSelector } from "../redux/hooks";

import CameraImageView from "../modals/CameraImageView";
import EmptyTable from "./common/EmptyTable";
import Pagination from "./common/Pagination";
import Title from "./common/Title";

const ParkingEvents = () => {
    const [viewCameraImageId, setViewCameraImageId] = useState<number | null>(
        null
    );
    const [search, setSearch] = useState<string>("");

    const userInfo = useAppSelector((state) => state.app.userInfo);

    const { page, setPage, totalPages, setTotalPages, itemsPerPage } =
        usePagination(20);

    const { data, isLoading } = useGetParkingEventLogsQuery(
        { search, page, itemsPerPage },
        { skip: !userInfo || userInfo.role === "USER" }
    );

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage));
        }
    }, [data, setTotalPages, itemsPerPage]);

    return (
        <div className="flex flex-col p-6 gap-4 mobile:p-4">
            <Title
                title="Журнал Парковок"
                setSearch={setSearch}
                setPage={setPage}
            />
            {!isLoading && (
                <>
                    {data && data.results.length > 0 ? (
                        <div className="bg-white w-full tablet:overflow-x-scroll flex flex-col rounded-xl overflow-hidden">
                            <div className="overflow-x-auto tablet:min-w-[850px] mobile:min-w-[800px]">
                                <div className="grid w-full bg-buttonColor h-[50px] items-center grid-cols-5">
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Адреса
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Номер авто
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Дія
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Дата
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate text-center">
                                        Зображення
                                    </div>
                                </div>
                                {data.results.map((event) => (
                                    <div
                                        key={event.id}
                                        className="grid w-full min-h-[50px] bg-dashboardTableColor items-center border-b-[1px] border-x-[1px] border-whiteButtonHoverColor last:rounded-b-xl grid-cols-5"
                                    >
                                        <div className="py-2 px-4 text-textDark truncate">
                                            {event.address ?? "-"}
                                        </div>
                                        <div className="py-2 px-4 text-textDark truncate">
                                            {event.license_plate}
                                        </div>
                                        <div className="py-2 px-4 text-textDark truncate">
                                            {
                                                PARKING_ACTION_TO_TEXT[
                                                    event.action
                                                ]
                                            }
                                        </div>
                                        <div className="py-2 px-4 text-textDark">
                                            {convertDateToLocalFormat(
                                                event.action_time
                                            )}
                                        </div>
                                        <div className="py-2 px-4 text-darkColor font-semibold truncate flex items-center justify-center">
                                            <button
                                                onClick={() =>
                                                    setViewCameraImageId(
                                                        event.id
                                                    )
                                                }
                                                className="h-[34px] rounded-lg min-w-[90px] font-semibold text-base flex items-center justify-between px-4 gap-4 bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300"
                                            >
                                                Переглянути
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає користувачів"
                        />
                    )}
                </>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
            {viewCameraImageId && (
                <CameraImageView
                    id={viewCameraImageId}
                    onClose={() => setViewCameraImageId(null)}
                />
            )}
        </div>
    );
};

export default ParkingEvents;
