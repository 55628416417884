import { useEffect, useState } from "react";

import { useDeleteCarMutation, useGetCarsListQuery } from "../api/appApi";
import usePagination from "../hooks/usePagination";
import { useAppSelector } from "../redux/hooks";
import { TCar } from "../utils/types";

import CarSessionsModal from "../modals/CarSessionsModal";
import EditCarModal from "../modals/EditCarModal";
import Pagination from "./common/Pagination";
import EmptyTable from "./common/EmptyTable";
import Table from "./common/Table";
import Title from "./common/Title";

const Automobiles = () => {
    const [carInfo, setCarInfo] = useState<TCar | null>(null);
    const [search, setSearch] = useState<string>("");
    const userInfo = useAppSelector((state) => state.app.userInfo);

    const { page, setPage, totalPages, setTotalPages, itemsPerPage } =
        usePagination(20);

    const { data, isLoading } = useGetCarsListQuery(
        { page, itemsPerPage, search },
        { skip: !userInfo || userInfo.role === "USER" }
    );
    const [deleteCar] = useDeleteCarMutation();

    const handleViewCarSessions = (id: string) => {
        setCarInfo(data?.results.find((car) => car.id === id) || null);
    };

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage));
        }
    }, [data, setTotalPages, itemsPerPage]);

    return (
        <div className="flex flex-col p-6 gap-4 mobile:p-4">
            <Title setPage={setPage} setSearch={setSearch} title="Автомобілі" />
            {!isLoading && (
                <div className="tablet:overflow-x-scroll">
                    {data && data.results.length > 0 ? (
                        <Table
                            type={"cars"}
                            data={data.results}
                            columns={["make", "license_plate", "white_listed"]}
                            editAction={true}
                            deleteAction={true}
                            deleteEndpoint={deleteCar}
                            detailsAction={true}
                            EditModal={EditCarModal}
                            detailsCallback={handleViewCarSessions}
                        />
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає автомобілів"
                        />
                    )}
                </div>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
            {carInfo && (
                <CarSessionsModal
                    onClose={() => setCarInfo(null)}
                    carInfo={carInfo}
                />
            )}
        </div>
    );
};

export default Automobiles;
