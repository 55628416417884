import { FaPlus } from "react-icons/fa";
import { FC, useEffect, useState } from "react";

type TitleProps = {
    title: string;
    setPage?: (page: number) => void;
    setSearch?: (search: string) => void;
    addAction?: boolean;
    addActionHandler?: () => void;
    addTitle?: string;
};

const Title: FC<TitleProps> = ({
    title,
    setPage,
    setSearch,
    addAction = false,
    addActionHandler,
    addTitle,
}) => {
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (setSearch && setPage) {
                setSearch(inputValue);
                setPage(1);
            }
        }, 700);

        return () => clearTimeout(timeout);
    }, [setSearch, setPage, inputValue]);

    return (
        <div className="flex flex-row mobile:flex-col items-center mobile:items-start justify-between gap-2 mobile:gap-4">
            <h5
                className={`text-[22px] leading-[28px] font-semibold text-backgroundColor tablet:w-auto tablet:pl-[46px] ${
                    setSearch ? "mobile:mt-[8px]" : ""
                }`}
            >
                {title}
            </h5>
            {setSearch && (
                <div className="flex items-start gap-2 mobile:w-full">
                    <div className="border border-borderColor rounded-lg px-4 h-[44px] w-[250px] max-w-[300px] flex items-center mobile:w-full mobile:max-w-[unset]">
                        <input
                            onChange={(e) => setInputValue(e.target.value)}
                            className="h-full text-base"
                            placeholder="Пошук"
                            value={inputValue}
                            type="text"
                        />
                    </div>

                    {addAction && (
                        <div
                            onClick={addActionHandler}
                            className="px-4 h-[44px] flex items-center justify-center rounded-lg bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300 cursor-pointer gap-2 text-base font-semibold select-none "
                        >
                            <FaPlus size={20} />
                            <span className="mobile:hidden">{addTitle}</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Title;
