import { IoMdClose } from "react-icons/io";
import { FC } from "react";

import { useGetParkingEventCameraImageQuery } from "../api/appApi";

import Modal from "./Modal";

type CameraImageViewProps = {
    id: number;
    onClose: () => void;
};

const CameraImageView: FC<CameraImageViewProps> = ({ id, onClose }) => {
    const { data } = useGetParkingEventCameraImageQuery(id);

    return (
        <Modal
            bodyStyles={{ borderRadius: "12px" }}
            customBodyClasses="bg-textColor max-w-[700px]"
            onClose={onClose}
        >
            <div className="flex flex-col items-center w-full gap-4">
                <h5 className="text-[22px] leading-[28px] font-semibold flex items-center justify-between w-full">
                    Перегляд зображення
                    <IoMdClose
                        className="cursor-pointer"
                        onClick={onClose}
                        size={28}
                    />
                </h5>
                {data && data?.camera_readings ? (
                    <img src={data.camera_readings} alt="" />
                ) : (
                    <div className="py-[50px]">Зображення відсутнє</div>
                )}
            </div>
        </Modal>
    );
};

export default CameraImageView;
