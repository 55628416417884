import { FaCarSide, FaMoneyCheckAlt, FaParking, FaUsers } from "react-icons/fa";
import { FC } from "react";
import { useGetDashboardGlobalStatsQuery } from "../api/appApi";

type TotalStatsProps = {
    selected: string;
};

const TotalStats: FC<TotalStatsProps> = ({ selected }) => {
    const { data } = useGetDashboardGlobalStatsQuery(selected);

    return (
        <div className="grid tablet:grid-cols-2 grid-cols-4  w-full gap-4">
            <div className="flex flex-col mobile:p-5 p-6 bg-buttonColor rounded-xl gap-2 tablet:p-4">
                <FaUsers className="mobile:text-[28px] text-[35px]" />
                <p className="font-semibold text-[18px] leading-[22px] mobile:leading-[18px]">
                    Користувачів
                </p>
                <h5 className="text-[26px] leading-[26px] mobile:text-[24px]  mobile:leading-[24px] font-extrabold">
                    {data?.total_users}
                </h5>
            </div>
            <div className="flex flex-col mobile:p-5 p-6 bg-buttonColor rounded-xl gap-2 tablet:p-4">
                <FaParking className="mobile:text-[28px] text-[35px]" />
                <p className="font-semibold text-[18px] leading-[22px] mobile:leading-[18px]">
                    Відвідувань
                </p>
                <h5 className="text-[26px] leading-[26px] mobile:text-[24px]  mobile:leading-[24px] font-extrabold">
                    {data?.total_parking_sessions}
                </h5>
            </div>
            <div className="flex flex-col mobile:p-5 p-6 bg-buttonColor rounded-xl gap-2 tablet:p-4">
                <FaMoneyCheckAlt className="mobile:text-[28px] text-[35px]" />
                <p className="font-semibold text-[18px] leading-[22px] mobile:leading-[18px]">
                    Зароблено
                </p>
                <h5 className="text-[26px] leading-[26px] mobile:text-[24px]  mobile:leading-[24px] font-extrabold">
                    ₴
                    {data?.total_earnings ? data?.total_earnings.toFixed(2) : 0}
                </h5>
            </div>
            <div className="flex flex-col mobile:p-5 p-6 bg-buttonColor rounded-xl gap-2 tablet:p-4">
                <FaCarSide className="mobile:text-[28px] text-[35px]" />
                <p className="font-semibold text-[18px] leading-[22px] mobile:leading-[18px]">
                    Автомобілів
                </p>
                <h5 className="text-[26px] leading-[26px] mobile:text-[24px]  mobile:leading-[24px] font-extrabold">
                    {data?.total_automobiles}
                </h5>
            </div>
        </div>
    );
};

export default TotalStats;
