import { useEffect, useState } from "react";

import { useGetSystemEventLogsQuery } from "../api/appApi";
import { useAppSelector } from "../redux/hooks";

import usePagination from "../hooks/usePagination";
import SystemEventItem from "./SystemEventItem";
import Pagination from "./common/Pagination";
import EmptyTable from "./common/EmptyTable";
import Title from "./common/Title";

const SystemEvents = () => {
    const [search, setSearch] = useState<string>("");
    const userInfo = useAppSelector((state) => state.app.userInfo);

    const { page, setPage, totalPages, setTotalPages, itemsPerPage } =
        usePagination(20);

    const { data, isLoading } = useGetSystemEventLogsQuery(
        { page, itemsPerPage, search },
        { skip: !userInfo || userInfo.role === "USER" }
    );

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage));
        }
    }, [data, setTotalPages, itemsPerPage]);

    return (
        <div className="flex flex-col p-6 gap-4 mobile:p-4">
            <Title
                title="Журнал Системи"
                setSearch={setSearch}
                setPage={setPage}
            />
            {!isLoading && (
                <div className="tablet:overflow-x-scroll ">
                    {data && data.results.length > 0 ? (
                        <div className="bg-white w-full flex flex-col rounded-xl overflow-hidden shadow-100 mobile:overflow-x-auto   tablet:min-w-[850px] mobile:min-w-[800px]">
                            <div className="tablet:min-w-[850px] mobile:min-w-[800px]">
                                <div className="min-w-max bg-buttonColor h-[50px] items-center grid grid-cols-4">
                                    <div className="py-2 px-4 text-darkColor font-semibold ">
                                        Виконавець
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold ">
                                        Дія
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold ">
                                        Назва об'єкту
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold ">
                                        Дата
                                    </div>
                                </div>
                                {data.results.map((event) => (
                                    <SystemEventItem
                                        event={event}
                                        key={event.id}
                                    />
                                ))}
                            </div>
                        </div>
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає користувачів"
                        />
                    )}
                </div>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
        </div>
    );
};

export default SystemEvents;
