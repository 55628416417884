import { useEffect, useState } from "react";

import { DISABILITY_STATUS_TO_TEXT } from "../utils/constants";
import {
    useEditUserDisabilityStatusMutation,
    useGetUsersListQuery,
} from "../api/appApi";
import usePagination from "../hooks/usePagination";
import { useAppSelector } from "../redux/hooks";

import EmptyTable from "./common/EmptyTable";
import Pagination from "./common/Pagination";
import { TUser } from "../utils/types";
import Title from "./common/Title";

const PeopleWithDisabilities = () => {
    const [search, setSearch] = useState<string>("");
    const userInfo = useAppSelector((state) => state.app.userInfo);

    const { page, setPage, totalPages, setTotalPages, itemsPerPage } =
        usePagination(20);

    const { data, isLoading } = useGetUsersListQuery(
        { page, itemsPerPage, search, filter: "&users_with_disability=true" },
        { skip: !userInfo || userInfo.role === "USER" }
    );
    const [changeDisabilityCertificateStatus] =
        useEditUserDisabilityStatusMutation();

    const handleEditStatus = (user: TUser) => {
        if (user.disability_certificate_status === "APPROVED") {
            changeDisabilityCertificateStatus({
                id: user.id,
                disability_certificate_status: "DISABLED",
            });
        } else {
            changeDisabilityCertificateStatus({
                id: user.id,
                disability_certificate_status: "APPROVED",
            });
        }
    };

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage));
        }
    }, [data, setTotalPages, itemsPerPage]);

    return (
        <div className="flex flex-col p-6 gap-4 mobile:p-4">
            <Title
                setPage={setPage}
                setSearch={setSearch}
                title="Користувачі з пільгами"
            />
            {!isLoading && (
                <div className="tablet:overflow-x-scroll">
                    {data && data.results.length > 0 ? (
                        <div className="bg-white w-full tablet:overflow-x-scroll flex flex-col rounded-xl overflow-hidden">
                            <div className="overflow-x-auto tablet:min-w-[850px] mobile:min-w-[850px]">
                                <div className="grid w-full bg-buttonColor h-[50px] items-center grid-cols-5">
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Електронна пошта
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Номер телефону
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Номер свідоцтва
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Статус
                                    </div>
                                    <div className="py-2 px-4 text-darkColor font-semibold truncate">
                                        Дії
                                    </div>
                                </div>
                                {data.results.map((user) => (
                                    <div
                                        key={user.id}
                                        className="grid w-full min-h-[50px] bg-dashboardTableColor items-center border-b-[1px] border-x-[1px] border-whiteButtonHoverColor last:rounded-b-xl grid-cols-5"
                                    >
                                        <div className="py-2 px-4 text-textDark truncate">
                                            {user.email}
                                        </div>
                                        <div className="py-2 px-4 text-textDark truncate">
                                            +{user.phone_number}
                                        </div>
                                        <div className="py-2 px-4 text-textDark truncate">
                                            {user.disability_certificate}
                                        </div>
                                        <div className="py-2 px-4 text-textDark truncate">
                                            {
                                                DISABILITY_STATUS_TO_TEXT[
                                                    (user.disability_certificate_status ||
                                                        "REJECTED") as keyof typeof DISABILITY_STATUS_TO_TEXT
                                                ]
                                            }
                                        </div>
                                        <div className="py-2 px-4 text-textDark">
                                            <button
                                                onClick={() =>
                                                    handleEditStatus(user)
                                                }
                                                className="h-[34px] rounded-lg min-w-[90px] font-semibold text-base flex items-center justify-between px-4 gap-4 bg-buttonColor hover:bg-buttonHoverColor transition-colors duration-300"
                                            >
                                                {user.disability_certificate_status ===
                                                "APPROVED"
                                                    ? "Деактивувати"
                                                    : "Активувати"}
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає користувачів"
                        />
                    )}
                </div>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
        </div>
    );
};

export default PeopleWithDisabilities;
