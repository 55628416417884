import { useState } from "react";
import {
    FaBars,
    FaTimes,
    FaCar,
    FaCreditCard,
    FaParking,
    FaSitemap,
    FaUsers,
} from "react-icons/fa";
import { MdOutlineLocalCarWash } from "react-icons/md";
import { BiSolidDashboard } from "react-icons/bi";
import { RiPagesFill } from "react-icons/ri";
import { IoIosSwitch } from "react-icons/io";
import { TbDisabled } from "react-icons/tb";
import { NavLink } from "react-router-dom";

import { useAuthContext } from "../contexts/AuthContext";
import { useAppSelector } from "../redux/hooks";

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const userInfo = useAppSelector((state) => state.app.userInfo);

    const authCtx = useAuthContext();

    const isAdmin = userInfo?.role === "ADMIN";

    const handleNavigationClick = () => {
        setIsOpen(false);
    };

    return (
        <>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className={`hidden mobile:block tablet:block absolute left-[16px] z-50 p-2 text-white bg-gray-800 rounded-md ${
                    isOpen
                        ? "top-[25px] mobile:top-[19px]"
                        : "mobile:top-[19px] top-[25px]"
                }`}
            >
                {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
            <div
                className={`mobile:fixed tablet:fixed static top-0 left-0 mobile:h-full tablet:h-full h-auto w-[260px] bg-backgroundColor flex flex-col justify-between py-6 transition-transform z-40 ${
                    isOpen
                        ? "mobile:translate-x-0 tablet:translate-x-0 flex"
                        : "mobile:-translate-x-full tablet:-translate-x-full mobile:hidden tablet:hidden"
                } `}
            >
                <div className="flex flex-col gap-1">
                    <h5 className="pl-0 tablet:pt-[60px] mobile:pt-[50px] w-full text-center text-textColor text-[22px] leading-[28px] font-semibold mb-4 mobile:mb-1">
                        Панель Керування
                    </h5>
                    {isAdmin && (
                        <>
                            <NavLink
                                to=""
                                onClick={handleNavigationClick}
                                className={({ isActive }) =>
                                    `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                        isActive
                                            ? "text-buttonColor bg-backgroundSecondaryColor"
                                            : "text-textColor"
                                    }`
                                }
                            >
                                <div className="w-[30px] flex justify-start">
                                    <BiSolidDashboard size={25} />
                                </div>
                                Огляд
                            </NavLink>
                            <NavLink
                                to="users"
                                onClick={handleNavigationClick}
                                className={({ isActive }) =>
                                    `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                        isActive
                                            ? "text-buttonColor bg-backgroundSecondaryColor"
                                            : "text-textColor"
                                    }`
                                }
                            >
                                <div className="w-[30px]">
                                    <FaUsers size={23} />
                                </div>
                                Користувачі
                            </NavLink>
                        </>
                    )}
                    <NavLink
                        to="parkings"
                        onClick={handleNavigationClick}
                        className={({ isActive }) =>
                            `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                isActive
                                    ? "text-buttonColor bg-backgroundSecondaryColor"
                                    : "text-textColor"
                            }`
                        }
                    >
                        <div className="w-[30px]">
                            <FaParking size={23} />
                        </div>
                        Парковки
                    </NavLink>
                    {isAdmin && (
                        <NavLink
                            to="cars"
                            onClick={handleNavigationClick}
                            className={({ isActive }) =>
                                `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                    isActive
                                        ? "text-buttonColor bg-backgroundSecondaryColor"
                                        : "text-textColor"
                                }`
                            }
                        >
                            <div className="w-[30px]">
                                <FaCar size={23} />
                            </div>
                            Автомобілі
                        </NavLink>
                    )}
                    <NavLink
                        to="payments"
                        onClick={handleNavigationClick}
                        className={({ isActive }) =>
                            `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                isActive
                                    ? "text-buttonColor bg-backgroundSecondaryColor"
                                    : "text-textColor"
                            }`
                        }
                    >
                        <div className="w-[30px]">
                            <FaCreditCard size={23} />
                        </div>
                        Оплати
                    </NavLink>
                    <NavLink
                        to="parking-events"
                        onClick={handleNavigationClick}
                        className={({ isActive }) =>
                            `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                isActive
                                    ? "text-buttonColor bg-backgroundSecondaryColor"
                                    : "text-textColor"
                            }`
                        }
                    >
                        <div className="w-[30px]">
                            <FaSitemap size={23} />
                        </div>
                        Журнал Парковок
                    </NavLink>
                    {isAdmin && (
                        <>
                            <NavLink
                                to="system-events"
                                onClick={handleNavigationClick}
                                className={({ isActive }) =>
                                    `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                        isActive
                                            ? "text-buttonColor bg-backgroundSecondaryColor"
                                            : "text-textColor"
                                    }`
                                }
                            >
                                <div className="w-[30px]">
                                    <RiPagesFill size={23} />
                                </div>
                                Журнал Системи
                            </NavLink>
                            <NavLink
                                to="carwash"
                                onClick={handleNavigationClick}
                                className={({ isActive }) =>
                                    `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                        isActive
                                            ? "text-buttonColor bg-backgroundSecondaryColor"
                                            : "text-textColor"
                                    }`
                                }
                            >
                                <div className="w-[30px]">
                                    <MdOutlineLocalCarWash size={23} />
                                </div>
                                Мийка
                            </NavLink>
                            <NavLink
                                to="people-with-disabilities"
                                onClick={handleNavigationClick}
                                className={({ isActive }) =>
                                    `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                        isActive
                                            ? "text-buttonColor bg-backgroundSecondaryColor"
                                            : "text-textColor"
                                    }`
                                }
                            >
                                <div className="w-[30px]">
                                    <TbDisabled size={23} />
                                </div>
                                Пільги
                            </NavLink>
                            <NavLink
                                to="control"
                                onClick={handleNavigationClick}
                                className={({ isActive }) =>
                                    `flex items-center ps-6 pe-2 h-[50px] gap-1 text-[18px] leading-[24px] font-semibold cursor-pointer transition-colors mobile:h-[44px] ${
                                        isActive
                                            ? "text-buttonColor bg-backgroundSecondaryColor"
                                            : "text-textColor"
                                    }`
                                }
                            >
                                <div className="w-[30px]">
                                    <IoIosSwitch size={23} />
                                </div>
                                Керування
                            </NavLink>
                        </>
                    )}
                </div>
                <button
                    onClick={() => authCtx?.signOutUser()}
                    className="bg-textColor h-[46px] rounded-lg font-semibold text-[18px] leading-[26px] w-[calc(100%-32px)] ms-4 hover:bg-whiteButtonHoverColor transition-colors duration-500"
                >
                    Вийти з акаунту
                </button>
            </div>
        </>
    );
};

export default Navigation;
