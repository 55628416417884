import { useAuthContext } from "../contexts/AuthContext";

import Google from "../assets/images/google.png";
import Apple from "../assets/images/apple.png";

const Auth = () => {
    const authCtx = useAuthContext();

    return (
        <div className="w-full min-h-screen flex items-center justify-center mobile:px-4">
            <div className="flex flex-col gap-4 max-w-[400px] w-full">
                <button
                    onClick={() => authCtx?.googleAuth()}
                    className="bg-darkColor h-[56px] rounded-xl px-4 font-base font-semibold flex items-center gap-2 justify-center text-textColor hover:bg-darkButtonHoverColor transition-colors duration-300"
                >
                    <img src={Google} alt="" />
                    Увійти через Google
                </button>
                <button
                    onClick={() => authCtx?.appleAuth()}
                    className="rounded-xl px-4 h-[56px] font-base font-semibold flex items-center gap-2 justify-center bg-borderColor hover:bg-whiteButtonHoverColor transition-colors duration-500"
                >
                    <img src={Apple} alt="" />
                    Увійти через Apple
                </button>
            </div>
        </div>
    );
};

export default Auth;
