import { useEffect, useState } from "react";

import { useDeleteUserMutation, useGetUsersListQuery } from "../api/appApi";
import usePagination from "../hooks/usePagination";
import { useAppSelector } from "../redux/hooks";

import EditUserModal from "../modals/EditUserModal";
import EmptyTable from "./common/EmptyTable";
import Pagination from "./common/Pagination";
import Table from "./common/Table";
import Title from "./common/Title";

const Users = () => {
    const [search, setSearch] = useState<string>("");
    const userInfo = useAppSelector((state) => state.app.userInfo);

    const [deleteUser] = useDeleteUserMutation();

    const { page, setPage, totalPages, setTotalPages, itemsPerPage } =
        usePagination(20);

    const { data, isLoading } = useGetUsersListQuery(
        { page, itemsPerPage, search },
        { skip: !userInfo || userInfo.role === "USER" }
    );

    const handleDeleteUser = (id: string) => {
        deleteUser(id);
    };

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage));
        }
    }, [data, setTotalPages, itemsPerPage]);

    return (
        <div className="flex flex-col p-6 gap-4 mobile:p-4">
            <Title
                setPage={setPage}
                setSearch={setSearch}
                title="Користувачі"
            />
            {!isLoading && (
                <div className="tablet:overflow-x-scroll">
                    {data && data.results.length > 0 ? (
                        <Table
                            type={"users"}
                            data={data.results}
                            columns={[
                                "email",
                                "phone_number",
                                "is_active",
                                "role",
                            ]}
                            deleteAction={true}
                            editAction={true}
                            deleteEndpoint={handleDeleteUser}
                            EditModal={EditUserModal}
                            beforeValue={{
                                phone_number: "+",
                            }}
                        />
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає користувачів"
                        />
                    )}
                </div>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
        </div>
    );
};

export default Users;
