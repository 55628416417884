import { Navigate, Route, Routes } from "react-router-dom";

import { useAppSelector } from "../redux/hooks";

import PeopleWithDisabilities from "../components/PeopleWithDisabilities";
import ParkingControl from "../components/ParkingControl";
import ParkingEvents from "../components/ParkingEvents";
import SystemEvents from "../components/SystemEvents";
import Automobiles from "../components/Automobiles";
import Navigation from "../components/Navigation";
import Overview from "../components/Overview";
import Parkings from "../components/Parkings";
import Payments from "../components/Payments";
import Carwash from "../components/Carwash";
import Users from "../components/Users";

const Dashboard = () => {
    const userInfo = useAppSelector((state) => state.app.userInfo);

    return (
        <div className="relative grid w-full h-screen grid-cols-[260px,1fr] tablet:grid-cols-1 tablet:h-[100dvh]">
            <Navigation />
            <div className="w-full h-screen tablet:h-[100dvh] overflow-y-auto">
                <Routes>
                    {userInfo?.role === "ADMIN" && (
                        <>
                            <Route path="/" element={<Overview />} />
                            <Route path="/users" element={<Users />} />
                            <Route path="/cars" element={<Automobiles />} />
                            <Route
                                path="/system-events"
                                element={<SystemEvents />}
                            />
                            <Route
                                path="/control"
                                element={<ParkingControl />}
                            />
                            <Route path="/carwash" element={<Carwash />} />
                        </>
                    )}
                    <Route path="/parkings" element={<Parkings />} />
                    <Route path="/payments" element={<Payments />} />
                    <Route path="/parking-events" element={<ParkingEvents />} />
                    <Route
                        path="/people-with-disabilities"
                        element={<PeopleWithDisabilities />}
                    />
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to={
                                    userInfo?.role === "ADMIN"
                                        ? "/"
                                        : "/parkings"
                                }
                            />
                        }
                    />
                </Routes>
            </div>
        </div>
    );
};

export default Dashboard;
