import { useEffect, useState } from "react";

import {
    useDeleteParkingMutation,
    useGetParkingsListQuery,
} from "../api/appApi";
import usePagination from "../hooks/usePagination";
import { useAppSelector } from "../redux/hooks";

import EditParkingModal from "../modals/EditParkingModal";
import AddParkingModal from "../modals/AddParkingModal";
import Pagination from "./common/Pagination";
import EmptyTable from "./common/EmptyTable";
import Table from "./common/Table";
import Title from "./common/Title";

const Parkings = () => {
    const [showParkingModal, setShowParkingModal] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const userInfo = useAppSelector((state) => state.app.userInfo);

    const [deleteParking] = useDeleteParkingMutation();

    const { page, setPage, totalPages, setTotalPages, itemsPerPage } =
        usePagination(20);

    const { data, isLoading } = useGetParkingsListQuery(
        { page, itemsPerPage, search },
        { skip: !userInfo || userInfo.role === "USER" }
    );

    const handleAddParking = () => {
        setShowParkingModal(true);
    };

    useEffect(() => {
        if (data) {
            setTotalPages(Math.ceil(data.count / itemsPerPage));
        }
    }, [data, setTotalPages, itemsPerPage]);

    return (
        <div className="flex flex-col p-6 gap-4 mobile:p-4">
            <Title
                title="Парковки"
                setSearch={setSearch}
                setPage={setPage}
                addAction={userInfo?.role === "ADMIN" ? true : false}
                addTitle={"Додати Парковку"}
                addActionHandler={handleAddParking}
            />
            {!isLoading && (
                <div className="tablet:overflow-x-scroll">
                    {data && data.results.length > 0 ? (
                        <Table
                            type={"parkings"}
                            data={data.results}
                            columns={[
                                "name",
                                "address",
                                "capacity",
                                "hourly_rate",
                                "night_rate",
                                "month_subscription",
                            ]}
                            deleteAction={
                                userInfo?.role === "ADMIN" ? true : false
                            }
                            editAction={true}
                            deleteEndpoint={deleteParking}
                            EditModal={EditParkingModal}
                            beforeValue={{
                                hourly_rate: "₴",
                                night_rate: "₴",
                                month_subscription: "₴",
                            }}
                            afterValue={{
                                capacity: " місць",
                                month_subscription: "/місяць",
                            }}
                        />
                    ) : (
                        <EmptyTable
                            isSearch={search.length > 0}
                            title="Поки немає парковок"
                        />
                    )}
                </div>
            )}
            {data && data.results.length !== 0 && totalPages > 1 && (
                <Pagination
                    page={page}
                    setPage={setPage}
                    totalPages={totalPages}
                />
            )}
            {showParkingModal && (
                <AddParkingModal onClose={() => setShowParkingModal(false)} />
            )}
        </div>
    );
};

export default Parkings;
