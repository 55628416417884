import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";

import ParkingsStats from "./ParkingsStats";
import TotalStats from "./TotalStats";
import Graph from "./Graph";

const Overview = () => {
    const [selected, setSelected] = useState("Весь час");
    const [isOpen, setIsOpen] = useState(false);

    const handleSelectFilter = (value: string) => {
        setSelected(value);
        setIsOpen(false);
    };

    return (
        <div className="p-6 flex flex-col gap-8 w-full mobile:p-4">
            <h5 className="text-[22px] leading-[28px] font-semibold text-backgroundColor flex items-center justify-between w-full tablet:pl-[46px]">
                Огляд
                <div className="relative">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="h-[44px] rounded-lg min-w-[90px] font-semibold text-base border-solid border-[1px] border-darkColor flex items-center justify-between px-4 gap-4"
                    >
                        {selected}
                        <IoIosArrowDown size={20} />
                    </button>
                    {isOpen && (
                        <div className="absolute top-[45px] right-0 w-full bg-white rounded-lg p-1 shadow-100 min-w-[140px]">
                            <div
                                className="text-base font-normal cursor-pointer h-[32px] flex items-center px-2 hover:bg-placeholderColor rounded-lg"
                                onClick={() => handleSelectFilter("Весь час")}
                            >
                                Весь час
                            </div>
                            <div
                                className="text-base font-normal cursor-pointer h-[32px] flex items-center px-2 hover:bg-placeholderColor rounded-lg"
                                onClick={() => handleSelectFilter("Сьогодні")}
                            >
                                Cьогодні
                            </div>
                            <div
                                className="text-base font-normal cursor-pointer h-[32px] flex items-center px-2 hover:bg-placeholderColor rounded-lg"
                                onClick={() => handleSelectFilter("Тиждень")}
                            >
                                Тиждень
                            </div>
                            <div
                                className="text-base font-normal cursor-pointer h-[32px] flex items-center px-2 hover:bg-placeholderColor rounded-lg"
                                onClick={() => handleSelectFilter("Місяць")}
                            >
                                Місяць
                            </div>
                            <div
                                className="text-base font-normal cursor-pointer h-[32px] flex items-center px-2 hover:bg-placeholderColor rounded-lg"
                                onClick={() => handleSelectFilter("Рік")}
                            >
                                Рік
                            </div>
                        </div>
                    )}
                </div>
            </h5>
            <TotalStats selected={selected} />
            <Graph selected={selected} />
            <ParkingsStats selected={selected} />
        </div>
    );
};

export default Overview;
